<template>
  <v-container fluid class="content-wrapper mb-4">
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600 mb-0">{{ $t("RateAndInventory.new_rateplan") }}</h4>
      <v-btn icon class="ml-3" @click="initAll()" :class="loadingData && 'animate__spining'" :disabled="loadingData">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>

    <div v-if="loadingData">
      <v-skeleton-loader type="heading" class="mb-5"></v-skeleton-loader>
      <v-row>
        <v-col cols="12" md="4">
          <v-card class="pa-3 py-5">
            <v-skeleton-loader type="image, list-item-two-line"></v-skeleton-loader
          ></v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card class="pa-3 py-5">
            <v-skeleton-loader type="image, list-item-two-line"></v-skeleton-loader
          ></v-card>
        </v-col>
      </v-row>
    </div>

    <v-row v-else row wrap>
      <v-col cols="12">
        <!-- <div class="col-md-12 py-2"> -->
        <div class="title-topic">
          {{ $t("RateAndInventory.rateplan_topic") }}
        </div>
        <!-- </div> -->
      </v-col>
      <div class="row col-md-12 px-5">
        <!-- <v-row> -->
        <div
          class="col-xl-3 col-lg-4 col-md-6"
          v-for="item in itemsAddRatePlan"
          :key="item._id"
        >
          <div
            class="card"
            style="height: 100%; box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%)"
          >
            <div class="card-img-top" style="background: rgb(223, 223, 223)">
              <img
                class="img-fluid"
                alt="Responsive image"
                :src="
                  item.type == 'custom'
                    ? require('@/assets/images/rateplan/Custom-Rate.jpg')
                    : require('@/assets/images/rateplan/Flexible-Rate.jpg')
                "
              />
            </div>
            <div
              class="card-body px-1 d-flex flex-column justify-space-between"
              style="min-height: 200px"
            >
              <h4 class="card-title bold text-center">
                {{ item.topic }}<br />
                {{ item.title }}
              </h4>

              <div class="d-flex justify-content-center">
                <div
                  class="btn-data-table btn-primary"
                  @click="gotoLink(item.linkName, null)"
                >
                  {{ $t("RateAndInventory.add_rateplan") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </v-row> -->
      </div>
      <div class="col-md-12 py-5">
        <div class="row">
          <div class="col-md-9">
            <div class="btn-data-table btn-warning" @click="gobackFunc()">
              {{ $t("RateAndInventory.btn_back") }}
            </div>
            <!-- <div class="btn-data-table btn-info ml-1">
              {{ $t("RateAndInventory.btn_save") }}
            </div> -->
          </div>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";

export default {
  name: "SigninRatePlanCreateScreen",
  metaInfo: {
    title: "Rate Plan",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    moment: moment,
    userToken: "",
    propertyID: "",
    value_element: "",
    itemsAddRatePlan: [],
    loadingData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-blue-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-blue-focus");
        element.classList.add("border-blue-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-blue-focus");
        element.classList.remove("border-blue-focus");
        this.value_element = "";
      }
    },
    initAll() {
      const self = this;
      self.loadingData = true;
      self.itemsAddRatePlan = [
        {
          _id: 1,
          topic: self.$t("RateAndInventory.custom_title"),
          title: self.$t("RateAndInventory.custom_label"),
          description: "",
          type: "custom",
          linkName: "SigninRatePlanNewAddFixScreen",
        },
        {
          _id: 2,
          topic: self.$t("RateAndInventory.full_title"),
          title: self.$t("RateAndInventory.full_label"),
          description: "",
          type: "full",
          linkName: "SigninRatePlanNewAddFullScreen",
        },
      ];
      setTimeout(() => {
        self.loadingData = false;
      }, 500);
    },
    gobackFunc() {
      this.loading = true;
      // EventBus.$emit("goBackPage");
      const link = {
        name: "SigninRatePlanScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      EventBus.$emit("changePathname", link);
    },
    gotoLink(linkName, rateplanId) {
      const link = {
        name: linkName,
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
          rateplanId,
        },
      };
      EventBus.$emit("changePathname", link);
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/signin/rateplan.css";
</style>
